/* ------------------------------------------------------------------------------

  
  
  1.  Global

      1.1 General
      1.2 Typography
      1.3 Fields
      1.4 Buttons
      1.5 Icons
      1.6 Loader
      1.7 Helpers

  2.  Navbar 

      2.1 Navbar elements
      2.2 Sidebar Menu
      
  3.  Homepage

      3.1 Sections
      3.2 Section Specialization
      3.3 Section Resume
      3.4 Section About
      3.5 Section Projects
      3.6 Section Partners
      3.7 Section Testimonials

  4. Project Detail

  5. Responsive styles

      5.1 Min width 576px
      5.2 Min width 768px
      5.3 Min width 992px
      5.4 Min height 500px
      5.5 Min height 600px
      5.6 Min height 700px



/*-------------------------------------------------------------------------------
 1. Global
-------------------------------------------------------------------------------*/

/* 1.1 General */
@import "~bootstrap/scss/bootstrap";
@import "ionicons.min.css";

/* https: //css-tricks.com/using-performant-next-gen-images-in-css-with-image-set/ */

// .intro {

//     background-image: image-set(url("../images/dropball.jpg?as=webp&width=500") 1x,
//             url("../images/dropball.jpg?as=webp&width=1000") 2x,
//             url("../images/dropball.jpg?as=jpg&width=500") 1x,
//             url("../images/dropball.jpg?as=jpg&width=1000") 2x);
// }
.btn-press:hover {
    /* Adjust the box-shadow to compensate for the button's movement */
    box-shadow: 5px 3px 0px #ffff !important;
    transform: translateY(4px);

}

.custom-container {
    padding-bottom: 2%;
    /* Adjust padding as needed */
    // font-size: 0.9rem;
    /* Adjust font size as needed */
}

.progress-bars .progress-item {
    margin-bottom: 1rem;
}

.progress-title {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.custom-progress {
    height: 1rem !important;
    /* Adjust the height of the progress bar */
    background-color: #e9ecef;
    border-radius: 5px;
}

.progress-bar {
    background-color: #83d3f8 !important;
    /* Adjust the color as needed */
    border-radius: 5px;
}

.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #7d2121;
    /* Change as needed */
    font-size: 24px;
    /* Change as needed */
    /* Add more styling for your overlay text here */
}


.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    border: 3px solid green;
}

.bgdiv {
    display: flex;
    justify-content: center;

}

.bgimg {
    object-fit: cover;
    height: auto;
    width: 100%;
}

.bgpic {
    width: 100vw;
    /*auto screen height */
    height: 100vh;
    display: flex;
}

.galdiv {
    display: flex;
    justify-content: center;

}

.galimg {
    object-fit: contain;
    height: auto;
    width: 100%;
}

.galpic {
    width: 80vw;
    /*auto screen height */
    height: 54vh;
    display: flex;
}

.imgServices {
    object-fit: contain;
    height: auto;
    width: 10vw;
}

.imgTrade {
    object-fit: contain;
    height: auto;
    width: 20vw;
    transform: translate(-2rem, 0);
}

.services {
    padding: 0;
}

.fp-next {
    right: 10rem;
}

.fp-prev {
    left: 10rem;
}

.vertical-title span {
    transition: none !important;
}

.imgFit {
    object-fit: contain;
    height: auto;
    width: 100%;
}

.fp-slidesNav.fp-bottom {
    // bottom: 15vh;
    // transform: translate(47%, -100%);
    bottom: 10%;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    align-content: stretch;
    flex-wrap: wrap;
}

.fp-slidesNav ul li a span {
    background: #fff !important;
}

/* Centered text */
.centered {
    position: absolute;
    top: 50%;
    left: auto;
    transform: translate(-50%, -50%);
}

.hidden-with-pos {
    position: fixed;
    top: -500px;
    left: -500px;
}

b,
strong {
    font-weight: 900;
    text-decoration: underline;
}



html {
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    // min-height: 100vh !important;
    // min-height: -webkit-fill-available !important;

}

body {
    font-family: "Poppins", sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 0.025em;
    line-height: 1.9428;
    color: #ffffff;
    background: #000000;
    -webkit-overflow-scrolling: touch;
    // min-height: -webkit-fill-available !important;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: #ffffff;
}

::selection {
    background-color: #ffffff;
    color: #fff;
}

-webkit-::selection {
    background-color: #ffffff;
    color: #fff;
}

::-moz-selection {
    background-color: #ffffff;
    color: #fff;
}

/* 1.2 Typography */

h1,
.h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 3.428rem;
    line-height: 1.2;
    font-weight: 600;
    color: #fff;
    letter-spacing: -0.025em;
    margin: 0.5em 0;
}

h2,
.h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 3.371rem;
    line-height: 1.166;
    color: #fff;
    letter-spacing: 0;
    margin: 2.6rem 0;
}

h3,
.h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 2.142rem;
    line-height: 1.2;
    letter-spacing: -0.05em;
    color: #fff;
    margin: 2.6rem 0;
}

h4,
.h4 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 1.714rem;
    line-height: 1.25;
    letter-spacing: 0.025em;
    color: #fff;
    margin: 2.6rem 0;
}

h5,
.h5 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 1.571rem;
    line-height: 1.1;
    letter-spacing: 0;
    color: #fff;
    margin: 2.6rem 0;
}

h6,
.h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 1.285rem;
    line-height: 1.3333;
    letter-spacing: 0;
    color: #fff;
    margin: 2.6rem 0;
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
    color: inherit;
}

h1 a:hover,
.h1 a:hover,
h2 a:hover,
.h2 a:hover,
h3 a:hover,
.h3 a:hover,
h4 a:hover,
.h4 a:hover,
h5 a:hover,
.h5 a:hover,
h6 a:hover,
.h6 a:hover {
    text-decoration: none;
}

strong {
    font-weight: 600;
}

p,
blockquote {
    margin-bottom: 2.6rem;
}

blockquote {
    font-size: 1.714rem;
    letter-spacing: 0.025em;
    line-height: 1.5;
    padding: 0;
    width: 80%;
    padding-left: 6.8rem;
    border-left: 0.3rem solid #c32865;
    margin-top: 5rem;
    color: #fff;
}

cite {
    display: block;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0;
    font-style: normal;
}

a {
    color: #c32865;
    -webkit-transition: color 0.3s ease-out;
    -o-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
}

a:hover {
    color: #c32865;
    outline: none;
}

a:focus {
    text-decoration: none;
}

/* 1.3 Fields */

input[type="text"],
input[type="address"],
input[type="url"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="tel"],
textarea {
    display: inline-block;
    width: 100%;
    font-size: 1rem;
    border-radius: 0.75em;
    color: #fff;
    background-color: #2f2f2f;
    border: 0;
    border-radius: 0;
    padding: 0.7rem 1rem 0.7rem;
    -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
    -webkit-appearance: none;
    -webkit-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;
}

textarea {
    padding-top: 0.8rem;
    height: 15rem;
    resize: none;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

input[type="text"]:focus,
input[type="address"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
textarea:focus {
    border-color: #2897c3;
    outline: 0;
    color: #fff;
}

input::-moz-placeholder {
    color: #999999;
    opacity: 1;
}

input:-ms-input-placeholder {
    color: #999;
}

input::-webkit-input-placeholder {
    color: #999;
}

textarea::-moz-placeholder {
    color: #999;
    opacity: 1;
}

textarea:-ms-input-placeholder {
    color: #999;
}

textarea::-webkit-input-placeholder {
    color: #999;
}

.form-group {
    margin-bottom: 30px;
}

label.error {
    color: #fff;
    margin: 0.8rem 0 0;
}

/* 1.4 Buttons */

.btn {
    color: #fff;
    letter-spacing: 0.025em;
    font-size: small;
    display: inline-block;
    border: 0;
    border-radius: 0;
    padding: 1.1em 2.28em 1em;
    background-color: #404144;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-out !important;
    transition: all 0.3s ease-out !important;
}

.btn:hover,
.btn:focus {
    background-color: #28b1c3;
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none !important;
}

/* 1.5 Icons */

.icon {
    display: inline-block;
}

.ion-ios-play {
    position: relative;
    width: 5rem;
    height: 5rem;
    padding-left: 0.2rem;
    line-height: 5rem;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 50%;
    font-size: 1.5rem;
    margin-right: 1.5rem;
    margin-top: 4rem;
    color: #fff;
    transition: all 0.3s ease-out;
}

.ion-ios-play:hover {
    color: #c32865;
    border: 1px solid #c32865;
}

.ion-ios-play:after {
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    height: 1px;
    width: 99999rem;
    background: #fff;
    margin-right: 2rem;
}

/* 1.6 Preloader */

.animsition {
    position: absolute;
    z-index: 1000;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
}

.loader {
    position: fixed;
    overflow: hidden;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #1b1b1b;
    color: #4b4b4b;
    text-align: center;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

/* 1.7 Helpers */

.text-muted {
    color: #999 !important;
}

.text-white {
    color: #fff;
}

.text-primary {
    color: #ffffff !important;
}

.masked {
    position: relative;
}

.masked:before,
.masked-03:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.1;
}

.masked-03:before {
    opacity: 0.3;
}

/*-------------------------------------------------------------------------------
  2. Navbar
-------------------------------------------------------------------------------*/

.navbar {
    position: absolute;
    display: block;
    z-index: 101;
    left: 0;
    top: 0;
    width: 100%;
    padding-top: 1rem;
    /* padding-bottom: 2rem; */
    color: #fff;
    margin: 0;
    border-radius: 0;
}

.navbar-bg {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    width: 100%;
    height: 0;
    background: #0000005e;
    z-index: -1;
    box-shadow: none;
    -webkit-transition: all 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
    -o-transition: all 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
    transition: all 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}

.navbar-fixed {
    position: fixed;
}

.navbar-fixed .navbar-bg {
    height: 100%;

    box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
}

.navbar .brand {
    float: left;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

/* 2.1 Navbar elements */

.brand {
    line-height: 1.6;
    width: 13%;
    color: #fff;
    display: inline-block;
}

a.brand:hover,
a.brand:focus {
    text-decoration: none;
    color: #fff;
}

.brand img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.65rem;
}

.brand-info {
    display: inline-block;
    vertical-align: middle;
}

.brand-name {
    font-weight: 600;
    font-size: 1.714rem;
    line-height: 1;
}

.brand-text {
    font-size: 0.857rem;
    font-weight: 300;
    margin-top: -0.3rem;
}

.navbar-toggle {
    float: right;
    display: block;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background: none;
    border: 0;
    margin-top: 0.8rem;
}

.navbar-toggle:focus {
    outline: none;
}

.navbar-toggle .icon-bar {
    background-color: #fff;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}

.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px;
}

.navbar-toggle:hover .icon-bar {
    background-color: #ffffff;
}

.navbar .social-list {
    float: right;
    margin-right: 10%;
}

.social-list {
    overflow: hidden;
}

.social-list-bottom {
    display: none;
    position: absolute;
    z-index: 10;
    right: 0;
    bottom: 2.4rem;
    color: #000;
}

.social-list a {
    float: left;
    font-size: 3rem;
    line-height: 1;
    color: white;
    margin-left: 2rem;
}

.social-list a:first-child {
    margin-left: 0;
}

.social-list a:hover {
    color: #c32865;
    text-decoration: none;
}

.navbar-spacer {
    float: right;
    margin: -1rem 4.5rem 0;
    width: 1px;
    height: 3.3rem;
    background-color: #3c3c3c;
}

.navbar-address {
    float: right;
    font-weight: 600;
    text-transform: uppercase;
}

.contacts {
    float: left;
    margin-left: 9rem;
    margin-top: 0.5rem;
}

.contacts .contact-item {
    display: inline-block;
    color: #fff;
    margin-right: 2rem;
}

.contacts .contact-item a {
    color: #fff;
}

.contacts .contact-item.spacer {
    opacity: 0.3;
}

.contact-address {
    margin-top: 0rem;
}

#success,
#error {
    display: none;
}

.form-group-message {
    display: none;
}

/* 2.2 Sidebar menu */

.click-capture {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    z-index: 99;
    opacity: 0;
    background: rgba(0, 0, 0, 0.3);
    transition: opacity 0.5s;
}

.menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 102;
    background-color: #1b1b1b;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.close-menu {
    position: absolute;
    z-index: 2;
    top: 2.3rem;
    font-size: 1.6rem;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    margin-top: 0.8rem;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.close-menu:hover {
    color: #ffffff;
}

.menu-list {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 5rem;
    bottom: 10.5rem;
    padding-left: 15%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    font-weight: 600;
    letter-spacing: -0.025rem;
    list-style: none;
    margin: 0;
    -webkit-transform: translateY(3rem);
    -ms-transform: translateY(3rem);
    -o-transform: translateY(3rem);
    transform: translateY(3rem);
    -webkit-transition: transform 0.5s 0.6s ease, opacity 0.5s 0.6s ease;
    -o-transition: transform 0.5s 0.6s ease, opacity 0.5s 0.6s ease;
    transition: transform 0.5s 0.6s ease, opacity 0.5s 0.6s ease;
}

.menu-list li a {
    color: #636363;
}

.menu-list .active>a,
.menu-list li a:hover {
    text-decoration: none;
    color: #fff;
}

.menu-list>li {
    font-size: 1.65rem;
    line-height: 1;
}

.menu-list>li {
    margin-top: 2.7rem;
}

.menu-list>li:first-child {
    margin-top: 0;
}

.menu-list ul {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-list ul li {
    font-size: 1.142rem;
    margin-top: 1.142rem;
}

.menu-list ul li:first-child {
    margin-top: 2.4rem;
}

.menu-footer {
    position: absolute;
    left: 15%;
    bottom: 4rem;
}

.menu-footer .social-list {
    opacity: 0;
    -webkit-transform: translateY(3rem);
    -ms-transform: translateY(3rem);
    -o-transform: translateY(3rem);
    transform: translateY(3rem);
    -webkit-transition: all 0.5s 0.7s ease;
    -o-transition: all 0.5s 0.7s ease;
    transition: all 0.5s 0.7s ease;
}

.menu-footer .social-list a {
    color: #7e7e7e;
    margin: 0;
    margin-right: 2.7rem;
}

.menu-footer .social-list a:last-child {
    margin-right: 0;
}

.menu-footer .social-list a:hover {
    color: #c32865;
}

.menu-footer .copy {
    opacity: 0;
    display: none;
    margin-top: 3.2rem;
    color: #fff;
    line-height: 1.71rem;
    -webkit-transform: translateY(2rem);
    -ms-transform: translateY(2rem);
    -o-transform: translateY(2rem);
    transform: translateY(2rem);
    -webkit-transition: all 0.5s 0.9s ease;
    -o-transition: all 0.5s 0.9s ease;
    transition: all 0.5s 0.9s ease;
}

.menu-is-opened .click-capture {
    visibility: visible;
    opacity: 1;
}

.menu-is-opened .menu {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.07);
}

.menu-is-opened .menu .menu-lang,
.menu-is-opened .menu-list,
.menu-is-opened .menu .menu-footer .social-list,
.menu-is-opened .menu .menu-footer .copy {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0);
    opacity: 1;
}

.menu-is-closed .menu .menu-lang,
.menu-is-closed .menu .menu-list,
.menu-is-closed .menu .menu-footer .social-list,
.menu-is-closed .menu .menu-footer .copy {
    opacity: 0;
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;

}

/*-------------------------------------------------------------------------------
  3. Homepage
-------------------------------------------------------------------------------*/

.boxed {
    padding-left: 1.7rem;
    padding-right: 1.7rem;
}

.left-boxed {
    left: 1.7rem;
}

.right-boxed {
    right: 1.7rem !important;
}

.copy-bottom {
    display: none;
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 1rem;
    color: #000;
}

.copy-bottom.white {
    color: #fff;
}

#pp-nav {
    z-index: 100;
    width: 2.1428rem;
    margin-top: 0 !important;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

#pp-nav.right {
    right: 0;
}

#pp-nav .pp-nav-up,
#pp-nav .pp-nav-down {
    display: none;
    font-size: 1.3428rem;
    cursor: pointer;
    color: #000;
    text-align: center;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

#pp-nav .pp-nav-up {
    margin-bottom: 3.5rem;
}

#pp-nav .pp-nav-down {
    margin-top: 3.5rem;
}

#pp-nav li,
.pp-slidesNav li {
    margin: 1.928rem auto;
    width: 10px;
    height: 10px;
}

#pp-nav span,
.pp-slidesNav span {
    left: 0;
    top: 0;
    cursor: pointer;
    width: 8px;
    height: 8px;
    background: #000;
    border: 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

#pp-nav .pp-nav-up:hover,
#pp-nav .pp-nav-down:hover,
#pp-nav.white .pp-nav-up:hover,
#pp-nav.white .pp-nav-down:hover {
    color: #c32865;
}

#pp-nav.white .pp-nav-up,
#pp-nav.white .pp-nav-down {
    color: #fff;
}

#pp-nav.white span,
.pp-slidesNav span {
    background: #fff;
}

#pp-nav li .active span,
.pp-slidesNav .active span,
#pp-nav span:hover,
.pp-slidesNav span:hover {
    opacity: 1;
}

#pp-nav li .active span,
.pp-slidesNav .active span {
    opacity: 1;
    background: #28b1c3;
    transform: scale(1.6);
}

/* 3.1 Sections */
.pp-section {
    height: 100%;
    position: absolute;
    width: 100%;
}

.pagepiling .section {
    background: #000000;
    padding-top: 10.3rem;
    padding-bottom: 6rem;
}

.pagepiling .pp-scrollable {
    padding: 0;
    overflow-x: hidden;
}

.pp-scrollable .vertical-centred {
    padding-top: 10.3rem;
    padding-bottom: 6rem;
}

.pp-scrollable .vertical-centred.v2 {
    padding-top: 6rem;
    padding-bottom: 9rem;
}

.scroll-wrap {
    position: relative;
    min-height: 100%;
}

.scrollable-content {
    white-space: nowrap;
    width: 100%;
    font-size: 0;
}

.scrollable-content:before {
    content: "";
    display: inline-block;
    height: 100vh;
    vertical-align: middle;
}

.vertical-centred {
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    font-size: 1rem;
}

.boxed-inner {
    position: relative;
    padding: 0;
}

.section-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.vertical-title {
    position: absolute;
    text-align: center;
    font-weight: 600;
    // opacity: 0.05;
    font-size: 9rem;
    top: 50%;
    left: 3rem;
    transform: translateY(-50%);
    font-size: 3rem;
    opacity: 1;
}

.vertical-title span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    opacity: 0;
    overflow: hidden;
    // transition: all 0.5s 0.6s;
    white-space: nowrap;
    letter-spacing: 2.5rem;
}

.section.active .vertical-title span {
    letter-spacing: 0;
    opacity: 1;
}

/*.section .intro{
  opacity: 0;
  transform:scale(1.05);
  -webkit-transition: all 0.35s 0.6s ease;
  -o-transition:all 0.35s 0.6s ease;
  transition: all 0.35s 0.6s ease;
}

.section.active .intro{
  opacity: 1;
  transform: scale(1) translate(0);
}*/

.fadeY {
    opacity: 0;
    transition: all 0.5s 1.5s ease;
    -webkit-transform: translateY(-2rem);
    -ms-transform: translateX(-2rem);
    -o-transform: translateY(-2rem);
    transform: translateY(-2rem);
}

.fadeY-1 {
    transition: all 1s 0.5s;
}

.fadeY-2 {
    transition: all 1s 1s;
}

.fadeY-3 {
    transition: all 1s 1.5s;
}

.section.active .fadeY {
    opacity: 1;
    transform: translate(0);
}

.section .intro .project-title {
    opacity: 0;
    transition: all 0.5s;
    transform: translateY(2rem);
    transition-delay: 0.2s;
}

.section .intro .project-row:nth-child(2) .project-title {
    transition-delay: 0.4s;
}

.section .intro .project-row:nth-child(3) .project-title {
    transition-delay: 0.6s;
}

.section .intro .project-row:nth-child(4) .project-title {
    transition-delay: 0.8s;
}

.section.active .intro .project-title {
    transform: none;
    opacity: 1;
    transform: translateY(0);
}

.subtitle-top {
    line-height: 1.714;
    margin-top: 0;
    margin-bottom: 2.8rem;
    letter-spacing: 0.1em;
}

.title {
    letter-spacing: -0.025em;
    line-height: 1.33333;
    margin: 0 0 3rem;
}

.display-1 {
    font-size: 4.28rem;
    line-height: 1;
    letter-spacing: -0.05em;
    margin: 0;
}

.display-2 {
    font-size: 6.54rem;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -0.05em;
    margin: 0;
}

.popup-youtube {
    display: inline-block;
    color: #fff;
    font-size: 1.26rem;
}

.popup-youtube:hover {
    text-decoration: none;
}

/* 3.2 Section Specialization */

.icon-specialization {
    font-size: 5rem;
    color: #ffffff;
}

.row-specialization {
    // margin: 0 -2rem;
}

.col-specialization {
    padding: 0 2rem;
}

/* 3.3 Section Resume */

.section-3 .vertical-centred {
    padding-top: 6rem;
}

.resume-title {
    position: relative;
    color: #fff;
    margin: 0 0 2rem;
}

.resume-header {
    position: relative;
    z-index: 2;
    top: 5rem;
    padding: 0 3.6rem;
    transition: all 0.5s;
}

.resume-row {
    position: relative;
    padding-bottom: 2.2rem;
    margin-bottom: 2.2rem;
    border-bottom: 1px solid #fff;
    transition: all 0.5s;
}

.resume-row:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border: 0;
}

.col-resume {
    position: relative;
    top: 0;
    padding: 2.2rem 3.6rem 3.6rem;
    background: rgba(255, 255, 255, 0.1);
    transition: background 0.5s, top 0.5s;
}

/* .col-resume:hover {
  background: #c32865;
} */

.resume-type {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-weight: 500;
    position: relative;
    font-size: 1.125rem;
    margin-bottom: 1.2rem;
    transition: all 0.5s;
}

.resume-study {
    margin-bottom: 1rem;
}

.resume-date {
    font-size: 0.9rem;
    margin-bottom: 2rem;
}

.resume-text {
    margin-top: 1.2rem;
    margin-bottom: 0;
}

.resume-text.last {
    margin-bottom: 0;
}

/* 3.4 Section About */

.experience-box {
    display: inline-block;
    font-weight: 300;
    position: relative;
    white-space: nowrap;
    padding: 3.75rem;
    border: 1px solid #fff;
}

.experience-border {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    width: 20.15rem;
    height: 41.15rem;
    border: 0.657rem solid #ffeb00;
    left: 5rem;
    top: -2rem;
}

.experience-content {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.experience-number {
    font-family: "Montserrat", sans-serif;
    font-size: 23.142rem;
    line-height: 1;
    font-weight: 400;
    color: #fff;
}

.experience-info {
    position: relative;
    font-size: 1.25rem;
    line-height: 1.481;
    color: #fff;
    text-align: right;
}

@media (min-width: 576px) {
    .experience-info {
        margin-top: -1px;
    }
}

.experience-info div {
    display: inline-block;
    position: relative;
}

.experience-info div:after {
    position: absolute;
    right: 100%;
    content: "";
    width: 5rem;
    bottom: 0.8rem;
    height: 1px;
    background: #fff;
}

.progress-bars {
    margin-top: 3rem;
}

.progress {
    height: 2.9rem;
    margin-bottom: 2rem;
    border-radius: 0;
    background: #222222;
}

.progress:last-child {
    margin-bottom: 0;
}

.progress-bar-wrp {
    width: 0;
    overflow: hidden;
    transition: all 0.5s;
    height: 100%;
}

.progress:nth-child(2) .progress-bar-wrp {
    transition-delay: 0.2s;
}

.progress:nth-child(4) .progress-bar-wrp {
    transition-delay: 0.5s;
}

.progress:nth-child(6) .progress-bar-wrp {
    transition-delay: 0.8s;
}

.section.active .progress-bar-wrp,
.pp-easing .progress-bar-wrp {
    width: 100%;
}

.progress-bar {
    background-color: #2d9aad;
    height: 100%;
}

.progress-bars .number {
    color: #fff;
    margin-bottom: 1rem;
}

/* 3.5 Section Projects*/

.bg-changer {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.bg-changer .section-bg {
    opacity: 0;
    will-change: opacity, transform;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity 0.5s 0.2s ease, transform 5s 0.2s ease;
    -o-transition: opacity 0.5s 0.2s ease, transform 5s 0.2s ease;
    transition: opacity 0.5s 0.2s ease, transform 5s 0.2s ease;
}

.section-bg.mask:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    transition: all 0.5s;
}

.section-bg.mask.hide:after {
    opacity: 0;
}

.bg-changer .active {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
}

.bg-changer .section-bg:first-child {
    opacity: 1;
}

.project-row {
    position: relative;
    margin-bottom: 3rem;
}

.view-all-projects,
.project-row,
.project-detail-row {
    padding-left: 3rem;
}

.project-row:last-child {
    margin-bottom: 0;
}

.project-number {
    position: absolute;
    left: 0;
    top: 1rem;
    font-size: 1.25rem;
}

.project-row a,
.project-detail-row a {
    display: block;
    text-decoration: none;
    color: #fff;
    transition: opacity 0.3s;
}

.project-row a {
    opacity: 0.5;
}

.project-detail-row {
    position: relative;
}

.project-row a:after,
.project-row a.active:after,
.project-detail-row a:after {
    content: "";
    opacity: 0;
    position: absolute;
    top: 2.2rem;
    margin-left: -4rem;
    /* height: 1px; */
    background: #fff;
    width: 100000px;
    transition: opacity 0.3s;
}

.project-detail-row a:after {
    top: 5.5vmin;
}

.project-row a:hover:after,
.project-row a.active:after,
.project-detail-row a:hover:after {
    opacity: 1;
}

.project-row a.active {
    opacity: 1;
}

.project-title {
    font-size: 3.68rem;
    font-weight: 400;
    margin: 0;
}

.project-detail-row .project-title {
    font-size: 9vmin;
}

.project-category {
    font-size: 1rem;
}

.view-all {
    position: relative;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 1.26rem;
    margin-top: 2.3rem;
}

.view-all a {
    color: rgb(122, 175, 245);
    position: relative;
}

.view-all a:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    background: #fff;
    height: 1px;
    width: 1000000px;
    margin-right: 2rem;
}

.view-all a:hover {
    color: #c32865;
    text-decoration: none;
}

@media (min-width: 768px) {

    .view-all-projects,
    .project-row,
    .project-detail-row {
        padding-left: 20%;
    }

    .project-title {
        font-size: 4.68rem;
    }

    .project-row a:after,
    .project-row a.active:after,
    .project-detail-row {
        top: 3rem;
    }

    .project-number {
        top: 1.7rem;
    }

    .project-detail-row .project-number {
        top: 3vmin;
    }
}

/* 3.6 Section Partners */

.row-partners {
    margin: 0 -5rem 0;
}

.col-partner {
    padding: 0 5rem;
    margin-top: 5rem;
    text-align: center;
    transition: all 0.7s;
    opacity: 0;
}

.section.active .col-partner {
    opacity: 1;
}

.section.active .col-partner:nth-child(1) {
    transition-delay: 0.3s;
}

.section.active .col-partner:nth-child(2) {
    transition-delay: 0.4s;
}

.section.active .col-partner:nth-child(3) {
    transition-delay: 0.5s;
}

.section.active .col-partner:nth-child(4) {
    transition-delay: 0.6s;
}

.section.active .col-partner:nth-child(5) {
    transition-delay: 0.7s;
}

.section.active .col-partner:nth-child(6) {
    transition-delay: 0.8s;
}

.section.active .col-partner:nth-child(7) {
    transition-delay: 0.9s;
}

.section.active .col-partner:nth-child(8) {
    transition-delay: 1s;
}

.section.active .col-partner:nth-child(9) {
    transition-delay: 1.1s;
}

.section.active .col-partner:nth-child(10) {
    transition-delay: 1.2s;
}

.section.active .col-partner:nth-child(11) {
    transition-delay: 1.3s;
}

.section.active .col-partner:nth-child(12) {
    transition-delay: 1.4s;
}

.col-partner img {
    margin: 0 auto;
    max-width: 100%;
    /* opacity: 0.8; */
}

.col-partner:hover img {
    opacity: 1;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

/* 3.7 Section Testimonials */

.bg-quote {
    position: absolute;
    right: 12%;
    top: 20%;
}

.bg-quote:before {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    width: 138px;
    height: 276px;
    /* background: url(../images/bg/quote.png) no-repeat; */
}

.bg-quote:after {
    position: absolute;
    content: "";
    right: 138px;
    width: 138px;
    height: 276px;
    margin-right: 5.75rem;
    /* background: url(../images/bg/quote.png) no-repeat; */
}

.review-row {
    position: relative;
    padding-top: 3rem;
    padding-bottom: 5rem;
}

.review-row:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100000px;
    height: 1px;
    background: #fff;
}

.review-carousel .text {
    font-weight: 300;
    font-size: 1.275rem;
    line-height: 1.675;
    margin: 0;
}

.review-carousel .text p {
    margin: 0;
}

.author-name {
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    text-transform: uppercase;
    line-height: 1.333;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.review-carousel .owl-nav {
    position: relative;
    top: 0;
    margin-top: 7.7vh;
    -webkit-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
}

.review-carousel .owl-prev,
.review-carousel .owl-next {
    font-size: 1.2857rem;
    width: 3.57rem;
    height: 3.57rem;
    line-height: 3.57rem;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    margin-right: 0.857rem;
}

.review-carousel .owl-prev:before {
    font-family: "Linearicons";
    content: "\e93b";
}

.review-carousel .owl-next:before {
    font-family: "Linearicons";
    content: "\e93c";
}

.review-carousel .owl-prev:hover,
.review-carousel .owl-next:hover {
    background: #fff;
    color: #000;
}

.review-carousel .owl-prev.disabled,
.review-carousel .owl-next.disabled {
    cursor: default;
}

.review-carousel .owl-prev.disabled:hover,
.review-carousel .owl-next.disabled:hover {
    background: none;
    color: #fff;
}

.owl-dots {
    text-align: center;
}

.owl-dot {
    display: inline-block;
}

.owl-dot span {
    display: block;
    width: 8px;
    height: 8px;
    background: #fff;
    position: relative;
    margin: 0 10px;
    border-radius: 50%;
}

.owl-dot.active span {
    background: #ffffff;
    transform: scale(1.4);
}

/* ------------------------------------------------------------------------------- */
/*  4. Project Detail
/* ------------------------------------------------------------------------------- */

.content {
    padding-top: 17rem;
}

.row-project {
    margin-bottom: 10rem;
}

.image-holder {
    display: block;
    margin: 2rem auto 2rem;
    max-width: 100%;
}

.social-list-detail {
    margin-top: 10rem;
}

.social-list-detail a {
    font-size: 2rem;
    color: #999;
}

.social-list-detail a:hover {
    color: #fff;
}

.col-project-name h1 {
    margin-top: 0;
}

.col-project-info p {
    margin-bottom: 0;
}

.col-project-info h5 {
    margin-top: 0;
    margin-bottom: 1.2rem;
}

.project-detail-text {
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 5rem;
}

/* ------------------------------------------------------------------------------- */
/*  5. Responsive styles
/* ------------------------------------------------------------------------------- */
/* 5.1 Min width 200px */

@media (min-width: 200px) {
    html {
        font-size: 1em;
    }

    .contacts {
        font-size: x-small;
        margin-top: 0;
        margin-left: 0.5rem;
    }

    .contacts .contact-item {
        color: #fff;
        margin-right: 0.5rem;
        display: inline-block;
    }

    .menu {
        width: 50%;
    }

    .h2 {
        font-size: 2.5rem;
    }

    .project-title {
        font-size: 2rem;
    }

    .display-2 {
        font-size: 2.5rem;
    }

    .experience-box {
        padding: 1.75rem;
    }

    .experience-number {
        font-size: 11rem;
    }

    .project-number {
        position: relative;
        top: 0rem;
    }

    .services {
        padding-top: 3em;
        padding-bottom: 3em;
    }

    .imgServices {

        width: 30vw;
        transform: translate(5rem, 0);
    }

    .contact-address {
        // font-size: 1rem;
        padding-top: 4rem;
    }

    h5 {

        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    textarea {
        height: 6rem;
    }

    .galpic {
        width: 65vw;
    }

    .imgTrade {
        object-fit: contain;
        height: auto;
        width: 40vw;
        transform: translate(5rem, 0);
    }

    .fp-arrow.fp-prev {
        border-width: 4vw 3vw 4vw 0;
    }

    .fp-arrow.fp-next {

        border-width: 4vw 0 4vw 3vw;
    }

    .fp-next {
        right: 5vw;
    }

    .fp-prev {
        left: 5vw;
    }

    #fp-nav.fp-right {
        right: 0px !important;
    }

    .social-list a {
        font-size: 2rem;
    }
}

/* 5.1 Min width 400px */

@media (min-width: 400px) {
    html {
        font-size: 12px;
    }

    #fp-nav.fp-right {
        right: 5px !important;
    }

    .contacts {
        font-size: small;
        margin-top: 0;
        margin-left: 0.5rem;
    }

    .contacts .contact-item {
        color: #fff;
        margin-right: 0.5rem;
        display: inline-block;
    }




    .form-group {
        margin-bottom: 4px;
    }

    .project-detail-text {
        margin-bottom: 0rem;
    }

    .image-holder {
        margin: 0em;
    }

    .experience-box {
        padding: 1.75rem;
    }

    .experience-number {
        font-size: 11rem;
    }

    .progress-bars {
        margin-top: 1rem;
    }

    .progress {
        margin-bottom: 0.2rem;
    }

    .menu {
        width: 50%;
    }

    .display-2 {
        font-size: 3.5rem;
    }

    .h2 {
        font-size: 3rem !important;
    }

    .project-number {
        position: relative;
        top: 0rem;
    }

    .services {
        padding-top: 3em;
        padding-bottom: 3em;
    }

    .imgServices {

        width: 15vw;
        transform: translate(5rem, 0);
    }

    .contact-address {
        // font-size: 1rem;
        padding-top: 4rem;
    }

    h5 {

        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    textarea {
        height: 6rem;
    }

    .galpic {
        width: 65vw;
    }

    .imgTrade {
        object-fit: contain;
        height: auto;
        width: 40vw;
        transform: translate(5rem, 0);
    }

    .fp-next {
        right: 8.5vw;
    }

    .fp-prev {
        left: 8.5vw;
    }

    #fp-nav.fp-right {
        right: 0px;
    }

    .social-list a {
        font-size: 2rem;
    }

}

/* 5.1 Min width 576px */

@media (min-width: 576px) {
    html {
        font-size: 12px;
    }

    #fp-nav.fp-right {
        right: 17px !important;
    }

    .contacts {
        font-size: revert;
        float: left;
        margin-top: .5rem;
        margin-left: 9rem;
    }

    .contacts .contact-item {
        color: #fff;
        margin-right: 2rem;
        display: inline-block;
    }

    .form-group {
        margin-bottom: 4px;
    }

    .project-detail-text {
        margin-bottom: 0rem;
    }

    .image-holder {
        margin: 0em;
    }

    .menu {
        width: 50%;
    }

    .display-2 {
        font-size: 4rem;
    }

    .project-number {
        position: relative;
    }

    .imgTrade {
        object-fit: contain;
        height: auto;
        width: 40vw;
        transform: translate(3rem, 0);
    }

    .fp-next {
        right: 10.5vw;
    }

    .fp-prev {
        left: 10.5vw;
    }

    .social-list a {
        font-size: 3rem;
    }
}

/* 5.2 Min width 768px */

@media (min-width: 768px) {
    html {
        font-size: 13px;
    }

    .display-2 {
        font-size: 4rem;
    }

    .project-number {
        position: absolute;
    }

    /* Sidebar Menu */

    .menu {
        width: 50%;
    }

    /* Homepage */

    .boxed {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .boxed-inner {
        padding-left: 0;
    }

    .right-boxed {
        right: 3rem !important;
    }

    .left-boxed {
        left: 3rem;
    }

    /* Section About */

    .section-about {
        padding-top: 12rem;
    }

    .owl-dots {
        text-align: left;
        padding-left: calc(41% + 15px);
    }

    .services {
        padding-top: 3em;
        padding-bottom: 3em;
    }

    .imgServices {

        width: 15vw;
    }

    .contact-address {
        // font-size: 1rem;
        padding-top: 4rem;
    }

    h5 {

        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    textarea {
        height: 10rem;
    }

    .galpic {
        width: 65vw;
    }

    .imgTrade {

        transform: translate(0, 0);
    }

    .fp-next {
        right: 12.5vw;
    }

    .fp-prev {
        left: 12.5vw;
    }

    .social-list a {
        font-size: 3rem;
    }
}

/* 5.3 Min width 992px */

@media (min-width: 992px) {
    html {
        font-size: 14px;
    }

    .navbar {
        /* padding: 2.7rem 0; */
    }

    /* Sidebar Menu */

    .menu {
        width: 35%;
        max-width: 35rem;
    }

    /* Homepage */

    .boxed {
        padding-left: 4.2857rem;
        padding-right: 4.2857rem;
    }

    .right-boxed {
        right: 4rem !important;
    }

    .left-boxed {
        left: 4.2857rem;
    }

    .services {
        padding-top: 3em;
        padding-bottom: 3em;
    }

    .imgServices {

        width: 10vw;
        transform: translate(0rem, 0);
    }

    .contact-address {
        // font-size: 1rem;
        padding-top: 4rem;
    }

    h5 {

        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    textarea {
        height: 6rem;
    }

    .galpic {
        width: 65vw;
    }

    .imgTrade {
        object-fit: contain;
        height: auto;
        width: 20vw;
        transform: translate(-2rem, 0);
    }

    .fp-next {
        right: 13.5vw;
    }

    .fp-prev {
        left: 13.5vw;
    }

    #fp-nav.fp-right {
        right: 1.5vw;
    }

    .social-list a {
        font-size: 4rem;
    }
}

@media (min-width: 1700px) {
    .imgTrade {
        object-fit: contain;
        height: auto;
        width: 15vw;
        transform: translate(-2rem, 0);
    }

    #fp-nav.fp-right {
        right: 1.5vw;
    }

}

@media (min-width: 2400px) {
    .imgTrade {
        object-fit: contain;
        height: auto;
        width: 10vw;
        transform: translate(-2rem, 0);
    }

    .imgServices {
        width: 7vw;
    }

}

/* 5.4 Min Height 500px */

@media (min-height: 500px) {

    #pp-nav .pp-nav-up,
    #pp-nav .pp-nav-down {
        display: block;
    }


    .menu .menu-lang {
        top: 5rem;
    }

    .menu-list {
        top: 7rem;
        // bottom: 2rem;
    }

    .menu-list>li {
        font-size: 1rem;
        margin-top: 1rem;
    }

    .menu-footer {
        bottom: 2rem;
    }

    .menu-footer .copy {
        display: block;
        font-size: 0.7rem;
        margin-top: 1.2rem;

        line-height: 1rem;

    }
}

/* 5.5 Min Height 600px */

@media (min-height: 600px) {
    /* Sidebar Menu */

    .menu .menu-lang {
        top: 7rem;
    }

    .menu-list {
        top: 7rem;
        // bottom: 2rem;
    }

    .menu-list>li {
        font-size: 1.2rem;
    }

    .menu-footer {
        bottom: 4rem;
    }

    .menu-footer .copy {
        display: block;
        font-size: 0.7rem;
        margin-top: 1.2rem;

        line-height: 1rem;

    }

    .col-specialization {
        padding: 0 2rem;
    }
}

/* 5.6 Min Height 700px */

@media (min-height: 700px) {
    .copy-bottom {
        display: block;
        font-size: x-small;
    }

    .social-list-bottom {
        display: block;
    }

    .text-uppercase {
        font-size: 1.5rem;
        margin-bottom: 2rem;
        margin-top: 1rem;
    }

    .col-specialization {
        padding: 0 2rem;
    }

    .menu .menu-lang {
        // top: 5rem;
    }

    .menu-list {
        // top: 7rem;
        bottom: 2rem;
    }

    .menu-list>li {
        font-size: 2rem;
        margin-top: 2.5rem;
    }

    .menu-footer {
        // bottom: 2rem;
    }

    .menu-footer .copy {
        display: block;
        font-size: 0.7rem;
        margin-top: 1.2rem;

        line-height: 1rem;

    }
}