/* Reset values
 * --------------------------------------- */
#fp-nav{
    font-size: 16px;
}
#fp-nav ul li a span:not(.fp-sr-only){
    left: 0;
    top: 0;
    background: transparent;
}
#fp-nav ul li a.active span:not(.fp-sr-only),
#fp-nav ul li:hover a.active span:not(.fp-sr-only),
#fp-nav ul li:hover a span:not(.fp-sr-only),
#fp-nav ul li a span:not(.fp-sr-only){
    margin: 0;
}
#fp-nav ul li a span:not(.fp-sr-only){
    position:relative;
    display: block;
    background: transparent;
}
#fp-nav ul li a.active span:not(.fp-sr-only),
#fp-nav ul li:hover a.active span:not(.fp-sr-only),
#fp-nav ul li:hover a span:not(.fp-sr-only),
#fp-nav ul li a span:not(.fp-sr-only){
    height: 12px;
    width: 12px;
}
#fp-nav ul li {
    margin: 12px 7px;
}
#fp-nav ul li .fp-tooltip{
    top:0;
    line-height: 12px;
}

/* Extension
 * --------------------------------------- */
#fp-nav ul li{
    width: auto;
    margin: auto;
    height: auto;
}
#fp-nav ul li a{
    display: table;
    width: 2.5em;
    height: 2em;
    margin: 0.5em 0;
}
#fp-nav ul li a.active span:not(.fp-sr-only),
#fp-nav ul li:hover a.active span:not(.fp-sr-only),
#fp-nav ul li:hover a span:not(.fp-sr-only),
#fp-nav ul li a span:not(.fp-sr-only){
    position: relative;
    display: block;
    overflow: hidden;
    width: 0.25em;
    height: 100%;
    margin: 0 0 0 1em;
    opacity: 0.7;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    border-radius: 0;
}

#fp-nav ul li a:not(.active) span:not(.fp-sr-only):focus .nav__item-inner,
#fp-nav ul li a:not(.active) span:not(.fp-sr-only):hover .nav__item-inner{
    opacity: 1;
}

#fp-nav ul li a span:not(.fp-sr-only)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.2,1,0.3,1);
    transition-timing-function: cubic-bezier(0.2,1,0.3,1);
}

#fp-nav ul li a.active span:not(.fp-sr-only)::before {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}
#fp-nav ul li .fp-tooltip{
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
#fp-nav.fp-right ul li .fp-tooltip {
    right: 100%;
}
#fp-nav.fp-left ul li .fp-tooltip {
    left: 100%;
}

/* Colors
 * --------------------------------------- */
#fp-nav ul li a.active span:not(.fp-sr-only),
#fp-nav ul li:hover a.active span:not(.fp-sr-only),
#fp-nav ul li:hover a span:not(.fp-sr-only),
#fp-nav ul li a span:not(.fp-sr-only){
    background: #fff;
}
#fp-nav ul li a span:not(.fp-sr-only)::before {
    background: #ff5722;
}

/* Big
 * --------------------------------------- */
.fp-big-nav #fp-nav ul li a{
    display: table;
    width: 2.5em;
    height: 3em;
    margin: 1em 0;
}